import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography as Text, Icon } from '@material-ui/core';

import { validateEmail } from '../helper/Validations';
import { Fade } from '../animations';

const styles = _ => ({
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsWrap: {
    marginTop: '4vh',
  },
});

function PasswordlessForm({
  classes,
  emailAlreadyExist,
  passwordlessEmail,
  changePasswordlessEmail,
  showPasswordless,
  linkSentToEmail,
  sendLoginLink,
  resetSendLoginLink,
}) {
  const onBackClick = () => {
    showPasswordless(false);
  };

  return !linkSentToEmail ? (
    <Fade>
      <div className={classes.flexColumn}>
        <Button variant="text" style={{ width: 80 }} color={'primary'} onClick={onBackClick}>
          {'< Back'}
        </Button>
        {emailAlreadyExist ? (
          <Text variant="h6">User already exists. Click to send login email</Text>
        ) : (
          <Text variant="h6">Enter your email address to login</Text>
        )}
        <TextField
          placeholder="myemail@provider.com"
          onChange={e => changePasswordlessEmail(e.target.value)}
          value={passwordlessEmail}
          error={!!passwordlessEmail && !validateEmail(passwordlessEmail)}
          margin="none"
          required
        />
      </div>
      <div className={classes.actionsWrap}>
        <div className={classes.flexColumn}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => sendLoginLink(passwordlessEmail)}
            disabled={!validateEmail(passwordlessEmail)}
          >
            SEND LOGIN LINK
          </Button>
        </div>
      </div>
    </Fade>
  ) : (
    <Fade>
      <div className={classes.flexWrap}>
        <Icon style={{ color: '#32CD32', marginRight: '5px' }}>check_circle</Icon>
        <Text variant="h6" color={'textPrimary'}>
          Please check your email for the login link
        </Text>
      </div>
      <div className={classes.actionsWrap}>
        <div className={classes.flexColumn}>
          <Button variant="text" color="primary" onClick={resetSendLoginLink}>
            TRY AGAIN
          </Button>
        </div>
      </div>
    </Fade>
  );
}

export default withStyles(styles)(PasswordlessForm);
