import React, { useState } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { Typography as Text, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as cx from 'classnames';

import actions from '../actions';
import * as selectors from '../selectors';
import { Fade } from '../animations';
import { QuestionWrap } from '../components';
import { ObjectList } from './index';
import { isHidden } from '../utils';
import { validateValue, validateBirthYear } from '../helper/Validations';

const styles = theme => ({
  root: {
    minHeight: '4vh',
    marginBottom: '2vh',
  },
  flex: { display: 'flex' },
  flexCentered: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  flexColumn: {
    flexDirection: 'column',
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    borderRadius: 40,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5vh',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  hasObject: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  headerText: {
    marginLeft: '1vw',
    color: theme.palette.secondary.main,
  },
  addBtn: {
    minWidth: '30%',
    backgroundColor: 'rgb(245,245,245)',
  },
  objectEditContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(254,254,254)',
    minHeight: '20vh',
    margin: '1vh',
    padding: '1.5vh',
  },
});

function ObjectsSection(props) {
  const {
    classes,
    loading,
    details,
    objectList,
    groupedObjectList,
    config,
    updateDetail,
    newObjects = {},
    updateNewObjDetail,
    clearNewObjDetails,
    updateEditObject,
    mainChartSum,
    chartDataSet,
    editingIndex,
    editingObject,
    editObject,
    removeObject,
    saveEditObject,
    cancelEditObject,
    currencySymbol,
    updateValidate,
  } = props;
  const { nodes = [], path, objectIs, isSingleObject, hiddenTrigger } = config;

  const [showForm, setShowForm] = useState(false);
  const newObj = newObjects[objectIs] || {};

  const toggleShowForm = () => setShowForm(!showForm);
  const updateObjDetail = (path, value) => {
    updateNewObjDetail(path, value);
  };
  const resetNewObj = () => {
    clearNewObjDetails();
    setShowForm(false);
  };
  const saveNewObject = () => {
    updateDetail(path, [...objectList, newObj]);
    resetNewObj();
  };
  const updateEditObjWithObjIs = (path, value) => updateEditObject(path, value);

  const useResidentialDetails = () => {
    updateObjDetail('fullAddress', details.extensionData.fullAddress);
    updateObjDetail('address.suburb', details.address.suburb);
    updateObjDetail('address.city', details.address.city);
    updateObjDetail('address.region', details.address.region);
    updateObjDetail('address.postcode', details.address.postcode);
    updateObjDetail('address.country', 'gbr');
    updateObjDetail('address.town', details.address.town);
    updateObjDetail('address.county', details.address.county);
  };

  const isButtonEnabled = () => {
    if (newObjects && newObjects.child) {
      if (path === 'extensionData.children') {
        if (!validateBirthYear(newObjects.child.birthYear)) {
          return true;
        } else {
          if (validateValue(newObjects.child.parentage)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    if (newObjects && newObjects.property) {
      if (path === 'extensionData.assetsAndLiabilities.properties') {
        if (validateValue(newObjects.property.estimatedValue)) {
          return true;
        } else {
          if (validateValue(newObjects.property.ownership)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    if (newObjects && newObjects.asset) {
      if (path === 'extensionData.assetsAndLiabilities.assets') {
        if (validateValue(newObjects.asset.estimatedValue)) {
          return true;
        } else {
          if (validateValue(newObjects.asset.ownership)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    if (newObjects && newObjects.liability) {
      if (path === 'extensionData.assetsAndLiabilities.liabilities') {
        if (validateValue(newObjects.liability.estimatedValue)) {
          return true;
        } else {
          if (validateValue(newObjects.liability.ownership)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  };

  return (
    !isHidden({
      details,
      hiddenTrigger,
    }) && (
      <Paper elevation={4} className={classes.root}>
        {loading && <LinearProgress />}
        <div className={cx(classes.flex, classes.header, 'noselect')}>
          <div className={classes.flexCentered}>
            {config.icon && (
              <div className={classes.iconWrap}>
                {config.icon === 'attach_money' ? (
                  <Text color="inherit" variant="h6">
                    {currencySymbol}
                  </Text>
                ) : (
                  <Icon>{config.icon}</Icon>
                )}
              </div>
            )}
            <Text color="textPrimary" variant="h6" className={classes.headerText}>
              {config.title}
            </Text>
          </div>
          {(!isSingleObject || (isSingleObject && objectList.length === 0)) && !showForm && (
            <Button variant="text" className={classes.addBtn} onClick={toggleShowForm}>
              {`+ Add ${config.objectIs}`}
            </Button>
          )}
        </div>
        <div>
          <Text color="textPrimary" className={classes.header}>
            {config.info}
          </Text>
        </div>
        {showForm && (
          <Fade timeout={200}>
            <Paper elevation={2} className={classes.objectEditContainer}>
              <Text variant="h6">Adding {objectIs}</Text>
              {objectIs === 'property' && (
                <Button
                  onClick={useResidentialDetails}
                  variant="outlined"
                  color="primary"
                  style={{ alignSelf: 'flex-start' }}
                >
                  Use my residential details
                </Button>
              )}
              <div className={classes.mainInputForm}>
                {nodes &&
                  nodes.map((nodeConfig, index) => {
                    return (
                      !isHidden({
                        details,
                        hiddenTrigger: nodeConfig.hiddenTrigger,
                      }) && (
                        <>
                          <QuestionWrap
                            key={index + nodeConfig.path}
                            {...nodeConfig}
                            updateDetail={updateObjDetail}
                            updateValidate={updateValidate}
                            details={newObj}
                            actualDetails={details}
                            currencySymbol={currencySymbol}
                          />
                        </>
                      )
                    );
                  })}
              </div>
              <div className={classes.formActionsWrap}>
                <Button
                  className={classes.actionBtn}
                  variant="contained"
                  color="primary"
                  disabled={isButtonEnabled()}
                  onClick={saveNewObject}
                >
                  ADD
                </Button>
                <Button className={classes.actionBtn} variant="text" onClick={resetNewObj}>
                  CANCEL
                </Button>
              </div>
            </Paper>
          </Fade>
        )}
        {!!objectList.length && (
          <ObjectList
            config={config}
            objectList={objectList}
            groupedObjectList={groupedObjectList}
            editingIndex={editingIndex}
            editingObject={editingObject}
            editObject={editObject}
            removeObject={removeObject}
            saveEditObject={saveEditObject}
            cancelEditObject={cancelEditObject}
            updateEditObjWithObjIs={updateEditObjWithObjIs}
            mainChartSum={mainChartSum}
            chartDataSet={chartDataSet}
            currencySymbol={currencySymbol}
            updateValidate={updateValidate}
            details={details}
          />
        )}
      </Paper>
    )
  );
}

export default connect(
  (state, ownProps) => ({
    loading: selectors.getAppLoading(state),
    details: selectors.getDetails(state),
    objectList: selectors.getObjectList(state, ownProps.config),
    groupedObjectList: selectors.getGroupedObjectList(state, ownProps.config),
    mainChartSum: selectors.getMainChartSum(state, ownProps.config),
    chartDataSet: selectors.getChartDataSet(state, ownProps.config),
    newObjects: selectors.getNewObjects(state),
    editingIndex: selectors.getEditingIndex(state, ownProps.config.objectIs),
    editingObject: selectors.getEditingObject(state, ownProps.config.objectIs),
    currencySymbol: selectors.getCurrency(state),
  }),
  (dispatch, ownProps) => ({
    updateDetail(path, value) {
      dispatch(actions.app.data.updateUserData(path, value));
    },
    updateValidate(path, value, details) {
      dispatch(actions.app.appState.updateValidate(path, value, details));
    },
    updateNewObjDetail(path, value) {
      dispatch(actions.app.data.updateNewObjData(path, value, ownProps.config.objectIs));
    },
    clearNewObjDetails() {
      dispatch(actions.app.data.clearNewObjData(ownProps.config.objectIs));
    },
    editObject(index, currentData) {
      dispatch(actions.app.data.editObject(index, currentData, ownProps.config.objectIs));
    },
    updateEditObject(path, value) {
      dispatch(actions.app.data.updateEditObject(path, value, ownProps.config.objectIs));
    },
    saveEditObject(path) {
      dispatch(actions.app.data.saveEditObject(path, ownProps.config.objectIs));
      dispatch(actions.app.data.cancelEditObject(ownProps.config.objectIs));
    },
    cancelEditObject() {
      dispatch(actions.app.data.cancelEditObject(ownProps.config.objectIs));
    },
    removeObject(path, index) {
      dispatch(actions.app.data.removeObject(path, index));
      dispatch(actions.app.data.cancelEditObject(ownProps.config.objectIs));
    },
  }),
)(withStyles(styles)(ObjectsSection));
