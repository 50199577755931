import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker as NormalDatePicker } from 'material-ui-pickers';
import moment from 'moment';

/*
MJJP This dosnt appear to be used see BirthDatePicker.
*/


const styles = _ => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function DatePicker({
  classes,
  value,
  onChange,
}) {
  return (
    <div className={classes.container} noValidate>
      <NormalDatePicker
        keyboard
        placeholder="DD/MM/YYYY"
        minDate={moment().subtract(110, 'year')}
        minDateMessage="Age can't be more than 110"
        maxDate={moment().subtract(16, 'year')}
        maxDateMessage="Age can't be less than 16"
        value={value && value !== '0001-01-01T00:00:00Z' ? moment(value) : null}
        onChange={val => onChange(val.format())}
        format="DD/MM/YYYY"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      />
    </div>
  );
}

export default withStyles(styles)(DatePicker);
