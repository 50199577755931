/* eslint-disable */
import axios from 'axios';
import moment from 'moment';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions from '../actions';
import * as selectors from '../selectors';
import * as Manipulations from '../helper/DataManipulationTasks';

export function* dataFlow() {
  yield takeEvery(actions.app.data.registerClient, function* takeRegisterClient() {
    try {
      yield put(actions.app.appState.startLoading('Setting up connection'));
      const accountDetails = yield select(selectors.getAccountDetails);
      const details = yield select(selectors.getDetails);
      //Set a default date. Required by Service Gateway API
      details.dateOfBirth = '1900-01-01T00:00:00Z';
      console.log(details);
      const apiUrls = yield select(selectors.getApiUrls);
      // Sanity check if the email is already registered
      // If true, forward them to login by email page
      const checkRespond = yield call(axios, {
        method: 'get',
        url: apiUrls.client + '/email',
        params: {
          email: details.email,
          accountHashId: accountDetails.hashId,
        },
      });
      yield put(actions.app.appState.startLoading('Sending your information'));
      if (checkRespond && checkRespond.data) {
        yield put(actions.app.temporary.showPasswordlessLogin(true));
        yield put(actions.app.temporary.changeEmail(details.email));
        yield put(actions.app.temporary.setEmailAlreadyExist());
      } else {
        const respond = yield call(axios, {
          method: 'post',
          url: apiUrls.client,
          data: {
            ...details,
            status: 'Pending',
            accountHashId: accountDetails.hashId,
            country: accountDetails.country,
          },
          params: {
            accountHashId: accountDetails.hashId,
          },
        });
        details.dateOfBirth = '';
        // TODO: Revisit some of the Sagas as there seems to be some overlap between what they yield
        // This is the first time the user is registered so we needs to populate these required fields as they are
        // necessary for validation.
        
        yield put(
          actions.app.appState.updateTempRequiredFields('dateOfBirth', respond.data.dateOfBirth),
        );
        yield put(actions.app.appState.updateTempRequiredFields('gender', respond.data.gender));
        yield put(
          actions.app.appState.updateTempRequiredFields(
            'maritalStatus',
            respond.data.maritalStatus,
          ),
        );
        yield put(actions.app.data.registerClientSuccess(respond.data));
        yield put(push('/details'));
      }

      yield put(actions.app.appState.stopLoading());
    } catch (e) {
      yield put(actions.app.appState.stopLoading());
      yield put(actions.app.data.registerClientFailure(e));
    }
  });

  yield takeEvery(actions.app.data.updateClientData, function* takeUpdateClientData({
    payload: { isSubmitting },
  }) {
     try {
      yield put(actions.app.appState.startLoading('Saving'));
      const timeStamp = moment().format();
      const details = yield select(selectors.getDetails);
      const apiUrls = yield select(selectors.getApiUrls);
      const outgoingDetails = Manipulations.partnerUpdateOutgoing(details);
      const respond = yield call(axios, {
        method: 'put',
        url: apiUrls.client ,
        data: {
          ...outgoingDetails,
          status: isSubmitting ? 'Live' : outgoingDetails.status,
          extensionData: {
            ...outgoingDetails.extensionData,
            factFindMilestone: {
              ...outgoingDetails.extensionData.factFindMilestone,
              submitted: isSubmitting
                ? timeStamp
                : outgoingDetails.extensionData.factFindMilestone.submitted,
            },
          },
        },
        params: {
          hashId: outgoingDetails.hashId,
        },
      });
      if (!isSubmitting) {
        yield put(actions.app.appState.nextStep());
      } else {
        yield put(
          actions.app.data.updateUserData('extensionData.factFindMilestone.submitted', timeStamp),
        );
        yield put(
          actions.app.data.updateUserData('status', 'Live'),
        );
        yield put(actions.app.temporary.justSubmitted());
        yield put(push('/'));
        yield put(actions.app.appState.setStep(0));
      }
      yield put(actions.app.appState.stopLoading());
      yield put(actions.app.data.updateClientDataSuccess(respond.data));
    } catch (e) {
      yield put(actions.app.appState.stopLoading());
      yield put(actions.app.data.updateClientDataFailure(e));
    }
  });

  yield takeEvery(actions.app.data.getClientData, function* takeGetClientData() {
    try {
      yield true;
    } catch (e) {
      throw e;
    }
  });
}
