import axios from 'axios';
import { takeEvery, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import decode from 'jwt-decode';
import auth0 from 'auth0-js';
import actions from '../actions';
import * as selectors from '../selectors';
import * as Manipulations from '../helper/DataManipulationTasks';

function getParameterByName(name, hash) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function* authFlow() {
  yield takeEvery(actions.app.temporary.sendLoginLink, function* takeSendEmailLink({
    payload: { email },
  }) {
    try {
      const apiConfig = yield select(selectors.getApiConfig);
      yield put(actions.app.appState.startLoading('Sending email'));
      const accountId = yield select(selectors.getAccountId);
      const checkEmailExistsUrl = `${apiConfig.apiUrls.client}/Email?email=${email}&accountHashId=${accountId}`;
      const emailExists = yield call(axios, { method: 'get', url: checkEmailExistsUrl });

      if (emailExists.data) {
        const auth = new auth0.WebAuth({
          clientID: apiConfig.clientDataKeys.client_id,
          domain: apiConfig.oAuthConfig.domain,
          redirectUri: apiConfig.callback,
          scope: apiConfig.oAuthConfig.scope,
          responseType: 'token id_token',
        });
        auth.passwordlessStart(
          {
            connection: 'email',
            send: 'link',
            email: email,
          },
          (err)=>{
            if (err) {
              throw err;
            }
          },
        );
      }
      yield put(actions.app.temporary.linkSentToEmail(true));
      yield put(actions.app.appState.stopLoading());
    } catch (e) {
      yield put(actions.app.appState.stopLoading());
      yield put(actions.app.temporary.sendLoginLinkFailure(e));
    }
  });

  yield takeEvery(actions.app.auth.logIntoClient, function* takeLogIntoClient({
    payload: { hash },
  }) {
    try {
      yield put(actions.app.appState.startLoading('Getting your information'));
      const idToken = getParameterByName('id_token', hash);
      const decoded = yield call(decode, idToken);
      const user_id = decoded.sub;
      const apiUrls = yield select(selectors.getApiUrls);

      const respond = yield call(axios, {
        method: 'get',
        url: `${apiUrls.client}/Acquire?userId=${user_id}`,
      });

      const accountId = respond.data.accountHashId;
      const clientHashId = respond.data.hashId;
      yield put(actions.app.appState.setAccountId(accountId));
      const details = respond.data;

      if (respond.data) {
        // TODO: Revisit some of the Sagas as there seems to be some overlap between what they yield
        // This is only run on load so we're populating our required fields so that the component kows about it before mounting

        yield put(
          actions.app.appState.updateTempRequiredFields('dateOfBirth', respond.data.dateOfBirth),
        );
        yield put(actions.app.appState.updateTempRequiredFields('gender', respond.data.gender));
        yield put(
          actions.app.appState.updateTempRequiredFields(
            'maritalStatus',
            respond.data.maritalStatus,
          ),
        );

        yield put(
          actions.app.auth.logIntoClientSuccess(Manipulations.partnerUpdateIncoming(respond.data)),
        );
        yield put(push('/details'));
      } else {
        yield put(
          actions.app.auth.logIntoClientSuccess({
            ...details,
            hashId: clientHashId,
          }),
        );
        yield put(push('/details'));
      }
      yield put(actions.app.appState.stopLoading());
    } catch (e) {
      yield put(actions.app.auth.logIntoClientFailure(e));
      yield put(actions.app.appState.stopLoading());
    }
  });

  yield takeEvery(actions.app.auth.logout, function* takeLogOut() {
    try {
      // Do cleanups
      yield put(actions.app.auth.logoutSuccess());
      yield put(push('/'));
    } catch (e) {
      yield put(actions.app.auth.logoutFailure(e));
    }
  });
}
