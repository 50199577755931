import React from 'react'
import {Pie} from 'react-chartjs-2';

const sampleData = {
	datasets: [{
		data: [300, 50, 100],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}]
};

export default function Chart({
	width = 100,
	height = 100,
	options = {},
	data = sampleData
}) {
  return (
    <div>
      <Pie
        data={data}
        width={width}
        height={height}
        legend={{display: false}}
        options={{
          maintainAspectRatio: false,
					...options
        }}/>
    </div>
  )
}