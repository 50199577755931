import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Typography as Text, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  papersContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70vh',
    margin: '3vh 15vw 0 15vw',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '3vh 2vw 0 2vw',
    },
    padding: '2vw',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '3vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function TermsAndConditions({ classes }) {
  return (
    <div>
      <Paper className={classes.papersContainer}>
        <Text variant="h5">ARKEN ACQUIRE – END USER LICENCE AGREEMENT</Text>
        <Text variant="caption">
          Arken Acquire is an online service by by which you can provide information to your
          Professional Adviser for the purposes of drafting your will and other documents.
        </Text>
        <Text variant="body1">Terms of Use</Text>
        <Text variant="caption">
          Arken.legal (UK) Limited (company number 02690082) (“Arken”, “we”, “us”, “our”) owns and
          operates the Arken Acquire through the website www.arken.legal or other weblink supplied
          by us for the Arken Acquire service (“Website”). Please read these Terms of Use with care
          before you use this Website. By using this Website, you signify your assent to these Terms
          of Use. If you do not wish to accept these Terms of Use, please do not use the Website.
        </Text>
        <Text variant="caption">
          These terms of use apply to your use of the Website and the gathering, storing and
          transmission of your personal information through the Website. To the extent that third
          parties provide you subsequent services using the information transmitted from the
          Website, those services will be governed by that party’s own terms and conditions and
          Arken will not be a party to such end agreement.
        </Text>
        <Text variant="caption">
          If you have any queries as to how to use the Arken Acquire service, please direct these to
          your Professional Adviser.
        </Text>
        <Text variant="body1">Disclaimer</Text>
        <Text variant="caption">
          Arken disclaims all warranties, express or implied, to the fullest extent permissible by
          law.
        </Text>
        <Text variant="body1">Limitation of Liability</Text>
        <Text variant="caption">
          Under no circumstances, including, but not limited to, negligence, shall we be liable for
          any special or consequential damages that result from the use of, or the inability to use,
          the Website, even if we have been advised of the possibility of such damages. In no event
          shall we have any liability to you for damages, losses and causes of action (whether in
          contract, tort (including, but not limited to, negligence), or otherwise) for accessing
          and interacting with this Website.
        </Text>
        <Text variant="body1">Privacy</Text>
        <Text variant="caption">
          Arken takes your privacy seriously. Our Data Protection Policy available at
          www.arken.legal governs the collection and procession of your personal information as
          between us and your Professional Adviser. The following paragraphs detail how we deal with
          any personal information we collect:
        </Text>
        <Text variant="caption">Who collects your personal information?</Text>
        <Text variant="caption">
          Arken collects the personal information from you to transfer it to your Professional
          Adviser pursuant to an agreement for services you have with your Professional Adviser (or
          in relation to a potential agreement for services). In compliance with Data Protection
          legislation, Arken is the Data Processor and your Professional Adviser is the Data
          Controller. The use of your data will also be governed by the terms and conditions,
          privacy policy and data protection policy of your Professional Adviser.
        </Text>
        <Text variant="caption">
          <ul>
            <li>
              Our physical office and postal address is General Wolfe House, 83 High Street,
              Westerham, TN16 1 PG.
            </li>
            <li>Our Registered Office is Kemp House, 152-160 City Road, London, EC1V 2NX.</li>
          </ul>
        </Text>
        <Text variant="caption">How do we collect your personal information?</Text>
        <Text variant="caption">
          Arken will collect your personal information when you visit or use our Website pages and
          when you use our services.
        </Text>
        <Text variant="caption">
          We also automatically receive and record information on our server logs from your browser
          including your IP address, cookie information and the pages on our Website you visited.
        </Text>
        <Text variant="caption">What personal information do we collect about you?</Text>
        <Text variant="caption">
          Arken may collect your name, postal address, e-mail address, telephone number and date of
          birth along with any other information which may be identifying or non-identifying that is
          necessary to deliver the services to you.
        </Text>
        <Text variant="caption">
          We also automatically receive and record your IP address, cookie information and
          information regarding which pages on our Website you access and when.
        </Text>
        <Text variant="caption">How do we use your personal information?</Text>
        <Text variant="caption">
          Arken respects your privacy and we will only use your personal information in the
          following ways:
        </Text>
        <Text variant="caption">
          <ul>
            <li>to provide you the Arken Acquire service</li>
            <li>
              to provide your information to third parties to allow them to provide services to you
              pursuant to a separate agreement
            </li>
            <li>to send you communications which you have requested</li>
            <li>to carry out our obligations arising from any contracts we enter into with you</li>
            <li>to request feedback from you on the services we provide</li>
            <li>to notify you of changes to our services</li>
            <li>to comply with the law</li>
            <li>to monitor behaviour on our Website (via cookies)</li>
          </ul>
        </Text>
        <Text variant="caption">
          Please be aware, if you choose to withhold your personal information when we require it,
          we may not be able to provide you with the services you have requested.
        </Text>
        <Text variant="caption">What is the legal basis for Data Collection?</Text>
        <Text variant="caption">
          <ul>
            <li>Legitimate purpose</li>
            <Text variant="caption">
              We collect and use your personal information for the legitimate business purposes of
              communicating with you when fulfilling your requests for services, sending you
              communications as part of delivering the service, notifying you of changes to our
              services, and monitoring behaviour on our Website to improve your browsing experience,
              help us to improve our Website and make our marketing campaigns more relevant.
            </Text>
            <li>Contract</li>
            <Text variant="caption">
              We may also need to use your personal information to perform our agreement with you so
              that we can carry out our obligations arising from any agreement we enter into with
              you or take initial steps at your request prior to entering into an agreement.
            </Text>
            <li>Consent</li>
            <Text variant="caption">
              We may also use your personal information where you give us your express consent.
            </Text>
            <li>Legal obligation</li>
            <Text variant="caption">
              We will use your personal information where we have to comply with the law e.g. we
              have to provide information to HMRC or to fraud agencies etc.
            </Text>
          </ul>
        </Text>
        <Text variant="caption">Where do we store your personal information?</Text>
        <Text variant="caption">
          In order to prevent unauthorised access or disclosure and to protect against loss we use
          up-to-date industry procedures to keep personally identifiable information as safe and
          secure as possible. We will use our best endeavours to protect your personal information
          but we cannot guarantee its security on transmission to the Website. Any transmission is
          at your own risk. We store your personal information on our servers in our data centres.
        </Text>
        <Text variant="caption">Our website is hosted in the UK by Amazon Web Services.</Text>
        <Text variant="caption">How long do we keep hold of your personal information?</Text>
        <Text variant="caption">
          We will hold your personal information only for as long as is necessary to provide our
          services, although sometimes we are required by law to keep your personal information for
          longer to comply with our legal obligations, resolve disputes and enforce our agreements.
          We review our retention periods for personal information on a regular basis.
        </Text>
        <Text variant="caption">Do we share or disclose your personal information?</Text>
        <Text variant="caption">
          Arken will not sell or rent your personal information to anyone. All the personal
          information we hold about you will be processed by our staff in the United Kingdom. We
          will send personal information about you to other companies or people only when:
        </Text>
        <Text variant="caption">
          <ul>
            <li>we have your consent to share the information</li>
            <li>
              it is necessary for the performance of services to you under separate agreement for
              services that you have with a third party
            </li>
            <li>we need to share your information to provide the services you have requested</li>
            <li>
              we need to send the information to companies who work on behalf of Arken to provide a
              product or service to you (unless we tell you differently, these companies do not have
              any right to use the personal information we provide to them beyond what is necessary
              to assist us)
            </li>
            <li>we respond to subpoenas, court orders or legal process</li>
            <li>
              we find that your actions on our web sites violate our terms of use, or any of our
              usage guidelines for specific products or services.
            </li>
          </ul>
        </Text>
        <Text variant="caption">How can you access your personal information?</Text>
        <Text variant="caption">
          You have a right to access your personal information by making a Subject Access Request to
          your Professional Adviser at any time you wish to see what personal information we hold
          about you and how we are using it so you can be satisfied it is being processed lawfully.
          You can ask for copies of this information and we will usually provide it free of charge
          and within one month of the date of your request (unless there are exceptional
          circumstances when we may then charge a reasonable fee to cover administrative charges or
          advise you that we require longer to deal with your request).
        </Text>
        <Text variant="caption">Your rights over your personal information</Text>
        <Text variant="caption">
          When you give us your personal information, under Data Protection legislation you have the
          following rights in relation to your personal information -right to be informed, right of
          access, right to rectification, right to erasure, right to restrict processing, right to
          data portability, right to object and rights in relation to automated decision making and
          profiling. Some of these rights have already been referred to above. For more information
          please visit this page provided by the Information Commissioner’s Office.
        </Text>
        <Text variant="caption">Other websites</Text>
        <Text variant="caption">
          This Website may contain links to and from other websites but please be aware that once
          you use any of these links to leave our Website, those other websites have their own
          privacy policies and we do not have any control over those other websites. We cannot be
          responsible for the protection and privacy of any information which you provide whilst
          visiting other websites so when you link to those other websites, you should read their
          own privacy policies.
        </Text>
        <Text variant="caption">Cookies</Text>
        <Text variant="caption">
          Our Website uses Cookies. A Cookie is a small file of letters and numbers that we put on
          your device when you visit our Website and which collects information about how you browse
          our Website. These Cookies allow us recognise your device. Please note that Cookies can’t
          harm your computer. Our Cookie policy is available on our website at www.arken.legal.
        </Text>
        <Text variant="body1">Questions</Text>
        <Text variant="caption">
          If you have any questions in relation to the provision of the Arken Acquire service or the
          drafting of your will or other documents, please contact your Professional Adviser.
        </Text>
        <Text variant="caption">
          You can make a request to your Professional Adviser in relation your Personal Data, in
          particular:
        </Text>
        <Text variant="caption">
          <ul>
            <li>if you wish us to stop collecting or processing your Personal Data</li>
            <li>if you wish to see what Personal Data we hold about you</li>
            <li>in relation to any cookies detailed in these terms of use</li>
            <li>if you wish to lodge a complaint about how we have handled your Personal Data</li>
          </ul>
        </Text>
        <Text variant="caption">
          If you are not satisfied with the response or you believe we are not processing your
          personal information in accordance with current data protection legislation you can
          complain to the Information Commissioner’s Office here.
        </Text>

        <div className={classes.buttonContainer}>
          <Button component={Link} to="/details" variant="contained" color="primary">
            Close
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(TermsAndConditions);
