import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Paper, Typography as Text } from '@material-ui/core';
import actions from '../actions';
import * as selectors from '../selectors';
import { haveRequiredFields } from '../utils';
import { getInvalidDateReasonText } from '../helper/Validations';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginTop: '15px',
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  papersContainer: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'rgb(255,226,120)',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '3vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  validationErrors:{
    color: 'red',
    textAlign:'center',
    paddingTop:'10px',
    fontWeight: 'bold'
  }
});

function SendInfo({
  classes,
  accountDetails,
  placeholder,
  title,
  disclaimers = [],
  value,
  onChange,
  submit,
  validated,
  tempRequiredFields,
}) {
  let companyCompletion =
    accountDetails &&
    accountDetails.extensionData &&
    accountDetails.extensionData.extensionData &&
    accountDetails.extensionData.extensionData.StaticContent &&
    accountDetails.extensionData.extensionData.StaticContent.completion;
  if (companyCompletion != null) {
    companyCompletion = companyCompletion.split('\n').map((i, key) => {
      return <p key={key}>{i}</p>;
    });
  }

  const defCompletion = disclaimers.map((text, index) => (
    <div key={index}>
      <Text variant="body2" key={index}>
        {text.replace('company_name', accountDetails.name)}
      </Text>
    </div>
  ));

  return (
    <div className={classes.container}>
      <Paper className={classes.papersContainer}>
        <Text variant="body2">
          Please note: Even though all the fields in this application aren't required, it still is
          in your best interest to complete as much as you can before sending us your information -
          it helps us when preparing your interview.
        </Text>
      </Paper>
      <span>{companyCompletion || defCompletion}</span>
      <TextField
        placeholder={placeholder || title}
        className={classes.textField}
        multiline
        autoFocus
        rows={6}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
      />
       {(!validated || !haveRequiredFields(tempRequiredFields)) &&
       <div className={classes.validationErrors}> 
            {getInvalidDateReasonText(tempRequiredFields[0].value)}
        </div>
        }
      <div className={classes.buttonContainer}>
       
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={!validated || !haveRequiredFields(tempRequiredFields)}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
export default connect(
  (state, _) => ({
    accountDetails: selectors.getAccountDetails(state),
    validated: selectors.getValidated(state),
    tempRequiredFields: selectors.getTempRequiredFields(state),
  }),
  (dispatch, _) => ({
    submit() {
      dispatch(actions.app.data.updateClientData(true));
    },
  }),
)(withStyles(styles)(SendInfo));
