import React, { useState } from 'react';
import { connect } from 'react-redux';
import { find, propEq, path as rPath } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { TextField, Paper, MenuItem, Typography as Text } from '@material-ui/core';
import SingleTextInput from './SingleTextInput';

import {
  getPossibleAddresses,
  getStructuredAddress,
  getPossibleAddressesUk,
  getStructuredAddressUk,
} from '../api/addresses';
import * as selectors from '../selectors';

const styles = _ => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

function AddressInput({
  classes,
  value = '',
  onChange,
  details,
  updateDetail,
  apiUrls,
  country,
  nodes,
}) {
  let addressFinderToUse = null;
  let detailedAddressFinderToUse = null;
  switch (country) {
    case 'nzl':
      addressFinderToUse = getPossibleAddresses;
      detailedAddressFinderToUse = getStructuredAddress;
      break;
    case 'gbr':
      addressFinderToUse = getPossibleAddressesUk;
      detailedAddressFinderToUse = getStructuredAddressUk;
      break;
    default:
      addressFinderToUse = getPossibleAddresses;
      detailedAddressFinderToUse = getStructuredAddress;
  }
  const [suggestions, setSuggestions] = useState([]);
  const getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
      ? []
      : suggestions.filter(_ => {
          const keep = count < 50;

          if (keep) {
            count += 1;
          }
          return keep;
        });
  };

  const fetchAddresses = async addr => {
    const respond = await addressFinderToUse(addr, apiUrls, country);
    let suggestions = [];

    if (respond && respond.completions) {
      suggestions = respond.completions.map(obj => ({
        label: obj.a,
        pxid: obj.pxid,
      }));
    }
    setSuggestions(suggestions);
  };

  const onSelectItem = async label => {
    const fullItem = find(propEq('label', label))(suggestions);
    const respond = await detailedAddressFinderToUse(fullItem.pxid, apiUrls, country);

    if (respond) {
      onChange(respond.number + ' ' + respond.street);
      updateDetail('address', {
        street: respond.number + ' ' + respond.street,
        suburb: respond.suburb,
        city: respond.city,
        region: respond.region,
        postcode: respond.postcode,
        country, // TODO
        coordinate: {
          latitude: respond.x,
          longitude: respond.y,
        },
      });
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <Downshift onSelect={onSelectItem}>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            selectedItem,
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  autoComplete: 'nope',
                  placeholder: `Enter ${country === 'gbr' ? 'a postcode' : 'an address'} ...`,
                  value,
                  onChange: e => {
                    onChange(e.target.value);
                    if (e.target.value.length < 9) {
                      fetchAddresses(e.target.value);
                    } else {
                      updateDetail('address', {
                        street: e.target.value,
                      });
                    }
                  },
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper
                    style={{ maxHeight: 200, overflow: 'auto' }}
                    className={classes.paper}
                    square
                  >
                    {getSuggestions(value).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion.label,
                        }),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
      <div style={{ display: 'flex', paddingTop: '10px', flexWrap: 'wrap' }}>
        {nodes.map(node => (
          <div style={{ flex: 1, flexDirection: 'column' }} key={node.path}>
            <Text color="default">{node.title}</Text>
            <SingleTextInput
              value={rPath(['address', node.path], details) || ''}
              onChange={v => updateDetail(`address.${node.path}`, v)}
              placeholder={node.placeholder}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default connect((state, _) => ({
  apiUrls: selectors.getApiUrls(state),
  country: selectors.getCountry(state),
}))(withStyles(styles)(AddressInput));
