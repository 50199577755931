import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography as Text } from '@material-ui/core';

import * as cx from 'classnames';
import * as selectors from '../selectors';

const styles = theme => ({
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  questionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '1vh',
    paddingLeft: '6px',
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    flex: 1,
    marginLeft: '1%',
    marginRight: '1%',
  },
  ageField: {
    width: '10%',
  },
  genderField: {
    marginLeft: '10px',
    width: '20%',
  },
  questionMain: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  asterisk: {
    color: 'red',
  },
  genderSelect: {
    minWidth: 150,
  },
  emailBox: {
    paddingLeft: '2%',
  },
  actionsWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
  },
  link: {
    cursor: 'pointer',
  },
  termsAndConditionsLink: {
    alignItems: 'start',
  },
  termsAndConditionsLinkCheck: {
    paddingRight: '5px',
    paddingTop: '1px',
  },
});

function NotSubscribed({
  classes,
  accountDetails,
}) {

  const { name: companyName } = accountDetails;
  
  return (
    <div>
      <div className={cx(classes.questionWrap, classes.noPaddingTop)}>
        <div className={classes.questionHeader}>
          <Text align="center" variant="h6">{companyName} is not currently subscribed to this service.</Text>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, _) => ({
    details: selectors.getDetails(state),
  }),
)(withStyles(styles)(NotSubscribed));
