import without from 'ramda/src/without';
import union from 'ramda/src/union';
import { filter as rFilter } from 'ramda';
import prepend from 'ramda/src/prepend';
import whereEq from 'ramda/src/whereEq';
import moment from 'moment';
import actions from '../actions';
import { ReducerBuilder } from './reducer-builder';
import { validateEmail,dateOlderThan16,dateLessThan110} from '../helper/Validations';

const defaultState = {
  accountId: null,
  country: 'gbr',
  accountDetails: null,
  activeStep: 0,
  isLoading: false,
  loadingMessage: null,
  showNotification: true,
  validated: true,
  invalid: [],
  required: ['gender', 'maritalStatus', 'dateOfBirth'],
  tempRequiredFields: [],
  termsAndConditionsTicked: false,
};

const validatePreferredContactMethod = payload => {
  const config = {
    phone: 'telephone',
    email: 'email',
    mobile: 'mobile',
  };
  const { preferredContactMethod } = payload.details;
  const { path: currentField, value: currentValue } = payload;

  /**
   * Editing the Preferred Contact selector
   */

  // Check if the corresponding preferred contact field has a value
  if (
    currentField === 'preferredContactMethod' &&
    payload.details[config[currentValue]] &&
    payload.details[config[currentValue]] !== ''
  ) {
    return currentValue === 'email' ? validateEmail(payload.details[config[currentValue]]) : true;
  }

  /**
   * Editing one of the Preferred Contact fields
   */

  // Are we editing any of the actual contact methods
  if (['telephone', 'email', 'mobile'].includes(currentField)) {
    const selectedPreferredContactMethod = config[preferredContactMethod];

    // Are we editing the actual preferred contact method
    if (currentField === selectedPreferredContactMethod && currentValue !== '') {
      return currentField === 'email' ? validateEmail(currentValue) : true;
    }

    // If we're esiting any of the others then just ensure then we still check the
    // currently selected methods and value stored
    if (
      currentField !== selectedPreferredContactMethod &&
      payload.details[selectedPreferredContactMethod] !== ''
    ) {
      return selectedPreferredContactMethod === 'email'
        ? validateEmail(payload.details[selectedPreferredContactMethod])
        : true;
    }
  }

  return false;
};

const reducer = new ReducerBuilder(defaultState)
  .handle(actions.app.appState.setAccountDetails, (state, action) => ({
    ...state,
    accountDetails: action.payload.accountDetails,
  }))
  .handle(actions.app.appState.setCountry, (state, action) => ({
    ...state,
    country: action.payload.country,
  }))
  .handle(actions.app.appState.setAccountId, (state, action) => ({
    ...state,
    accountId: action.payload.accountId,
  }))
  .handle(actions.app.appState.setStep, (state, action) => ({
    ...state,
    activeStep: action.payload.key,
  }))
  .handle(actions.app.appState.updateRequired, (state, action) => {
    const fieldName = action.payload.path;
    const fieldValue = action.payload.value;
    const outstandingRequiredFields = state.required;

      if (fieldName === 'dateOfBirth') {
            const newDate = moment(fieldValue, 'DD/MM/YYYY');
            if (newDate && newDate.isValid() && dateOlderThan16(newDate) && dateLessThan110(newDate)) {
              return {
                ...state,
                required: without(fieldName, outstandingRequiredFields),
              };
            }
            return {
              ...state,
              required: union([fieldName], outstandingRequiredFields),
            };
      }

      if (fieldValue !== '') {
            return {
              ...state,
              required: without(fieldName, outstandingRequiredFields),
            };
      }

      return {
        ...state,
        required: union([fieldName], outstandingRequiredFields),
      };

  })
  .handle(actions.app.appState.updateValidate, (state, action) => {
    if (['telephone', 'email', 'mobile', 'preferredContactMethod'].includes(action.payload.path)) {
      return {
        ...state,
        validated: validatePreferredContactMethod(action.payload),
      };
    }

    return {
      ...state,
    };
  })
  .handle(actions.app.appState.updateTempRequiredFields, (state, action) => {
    const { path: field, value } = action.payload;

    return {
      ...state,
      tempRequiredFields: prepend(
        {
          field,
          value,
        },
        rFilter(n => !whereEq({ field })(n) && n, state.tempRequiredFields),
      ),
    };
  })
  .handle(actions.app.appState.nextStep, state => ({
    ...state,
    activeStep: state.activeStep + 1,
  }))
  .handle(actions.app.appState.previousStep, state => ({
    ...state,
    activeStep: state.activeStep - 1,
  }))
  .handle(actions.app.appState.startLoading, (state, action) => ({
    ...state,
    isLoading: true,
    loadingMessage: action.payload.loadingMessage || null,
  }))
  .handle(actions.app.appState.stopLoading, state => ({
    ...state,
    isLoading: false,
    loadingMessage: null,
  }))
  .handle(actions.app.appState.setShowNotification, (state, action) => ({
    ...state,
    showNotification: action.payload.show,
  }))
  .handle(actions.app.auth.logoutSuccess, state => ({
    ...defaultState,
    accountDetails: state.accountDetails,
    country: state.country,
    accountId: state.accountId,
  }))
  .handle(actions.app.appState.setTermsAndConditionsTicked, (state, action) => ({
    ...state,
    termsAndConditionsTicked: action.payload.isChecked,
  }))
  .done();

export default reducer;
