import { authFlow } from './auth';
import { dataFlow } from './data';
import { themesFlow } from './themes';
import { call, fork, select, put, take } from 'redux-saga/effects';
import * as selectors from '../selectors';
import actions from '../actions';
import { getAccountDetails} from '../api/account';

export default function* fullAppSaga() {
  yield fork(authFlow);
  yield fork(dataFlow);
  yield fork(themesFlow);
  yield fork(authenticationFlow);
}

function* authenticationFlow() {
  yield take('persist/REHYDRATE');
  yield put(actions.app.temporary.rehydrated());
  yield put(actions.app.appState.startLoading('Preparing forms'));
  console.log('version-info : ', process.env.REACT_APP_VERSIONINFO);
    const isValidCountry = country => ['nzl', 'gbr', 'rso', 'aus'].indexOf(country) > -1;


  const currentPath = window.location.pathname;
  const pathComponents = currentPath.split('/').filter(Boolean);
  if (pathComponents.length === 2 && isValidCountry(pathComponents[0])) {
  
    yield put(actions.app.appState.setCountry(pathComponents[0]));
    yield put(actions.app.appState.setAccountId(pathComponents[1]));
  } else {
    yield put(actions.app.appState.setCountry('gbr'));
  }

  const apiUrls = yield select(selectors.getApiUrls);
  const accountId = yield select(selectors.getAccountId);
  if(accountId)
  {
  let accountDetails = yield call(getAccountDetails, accountId, apiUrls);
  yield put(actions.app.appState.setAccountDetails(accountDetails.data));
  accountDetails = yield select(selectors.getAccountDetails);
  }
  yield put(actions.app.appState.stopLoading());
}
