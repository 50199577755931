import React from 'react';
import contains from 'ramda/src/contains';
import without from 'ramda/src/without';
import { withStyles } from '@material-ui/core/styles';
import { Typography as Text, TextField } from '@material-ui/core';
import * as cx from 'classnames';

// This presence of this value will allow a custom value to be entered
const CUSTOM_FIELD_TRIGGER_VALUE = 'Other';
const CUSTOM_FIELD_MARITAL_STATUS = ['civilpartnership', 'inpartnership', 'stableunion'];

const styles = ({ palette }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    padding: '4px 9px 4px 9px',
    marginRight: '3px',
    marginBottom: '3px',
    backgroundColor: 'rgb(240,240,240)',
    color: 'grey',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.primary.light,
      color: palette.primary.contrastText,
    },
  },
  selected: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    '&:hover': {
      opacity: 0.7,
    },
  },
});

function PickOne({ classes, options = [], value: selectedValue, onChange }) {
  const onClick = val => () => {
    let newVal = val;

    if (contains(CUSTOM_FIELD_TRIGGER_VALUE, options) && val === CUSTOM_FIELD_TRIGGER_VALUE) {
      newVal = '';
    }

    onChange(newVal);
  };

  const handleChange = event => {
    onChange(event.target.value);
  };

  //Set marital status - based on Arken.Common.dll values and RegisterForm values
  const getSelectedStatus = val => {
    if (CUSTOM_FIELD_MARITAL_STATUS.includes(selectedValue)) {
      if (selectedValue === 'civilpartnership') {
        selectedValue = 'civil partnership';
      } else if (selectedValue === 'inpartnership') {
        selectedValue = 'living as partners';
      } else if (selectedValue === 'stableunion') {
        selectedValue = 'stable union';
      }
    }

    if (
      val === CUSTOM_FIELD_TRIGGER_VALUE &&
      !contains(selectedValue, without(CUSTOM_FIELD_TRIGGER_VALUE, options)) &&
      selectedValue !== null &&
      selectedValue !== undefined
    ) {
      return true;
    }

    if (!contains(selectedValue, options)) {
      return false;
    }

    return val === selectedValue;
  };

  return (
    <>
      <div className={classes.root}>
        {options.map((value, index) => (
          <div
            key={index}
            onClick={onClick(value)}
            role="button"
            className={cx('noselect', classes.tag, {
              [classes.selected]: getSelectedStatus(value),
            })}
          >
            <Text color="inherit" variant="caption">
              {getSelectedStatus(value) && <span>&#10003;</span>}
              {value.toUpperCase()}
            </Text>
          </div>
        ))}
      </div>
      {contains(CUSTOM_FIELD_TRIGGER_VALUE, options) &&
        !contains(selectedValue, without(CUSTOM_FIELD_TRIGGER_VALUE, options)) &&
        selectedValue !== null &&
        selectedValue !== undefined && <TextField value={selectedValue} onChange={handleChange} />}
    </>
  );
}

export default withStyles(styles)(PickOne);
