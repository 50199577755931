import actions from '../actions';
import { ReducerBuilder } from './reducer-builder';
import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#63a4ff',
      main: '#6ea7d2',
      dark: '#3F7CB6',
      contrastText: '#fff',
    },
    secondary: {
      light: '#63a4ff',
      main: '#3F7CB6',
      dark: '#6ea7d2',
      contrastText: '#fff',
    },
    common: {
      white: '#fff',
      lightBlue: '#D6EBF2',
    }
  },
  typography: {
    useNextVariants: true,
  },
});

const defaultState = {
  activeTheme: defaultTheme,
  themes: [
    defaultTheme
  ]
};

const reducer = new ReducerBuilder(defaultState)
  .handle(actions.app.themes.createThemeSuccess, (state, action) => ({
    ...state,
    themes: [
      ...state.themes,
      action.payload.theme
    ],
  }))
  .handle(actions.app.themes.setTheme, (state, action) => ({
    ...state,
    activeTheme: {
      ...action.payload.theme
    },
  }))
  .handle(actions.app.auth.logoutSuccess, () => defaultState)
  .done();

export default reducer;