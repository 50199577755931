import axios from 'axios';
const convert = require('xml-to-json-promise');

export const getPossibleAddresses = async function(addr = '', apiUrls, country) {
  const addrEn = encodeURIComponent(addr.trim());
  const addressFinderUrl = apiUrls.addressFinder;
  const addUrl = addressFinderUrl + `${addrEn}`;
  const headers = {
    'content-type': 'application/json',
  };

  const response = await axios({
    method: 'get',
    url: addUrl,
    headers: headers,
  });
  return response ? response.data : null;
};

export const getStructuredAddress = async function(pxid, apiUrls, country) {
  if (!pxid || pxid.trim() === '') {
    return null;
  } else {
    const addressFinderPxid = apiUrls.addressFinderPxid;
    const addUrl = addressFinderPxid + encodeURIComponent(pxid);
    const headers = {
      'content-type': 'application/json',
    };

    const response = await axios({
      method: 'get',
      url: addUrl,
      headers: headers,
    });
    return response ? response.data : null;
  }
};

export const getPossibleAddressesUk = async function(postcode = '', apiUrls, updateDetail) {
  const cleanedPostcode = postcode.replace(/\s/g, '');
  const postcodeEn = encodeURIComponent(cleanedPostcode);
  try {
    const addressFinderUrl =
      'https://ws1.postcodesoftware.co.uk/lookup.asmx/getAddress?account=2597&password=gkn67kil&postcode=';
    const addUrl = addressFinderUrl + `${postcodeEn}`;
    const headers = {
      'content-type': 'application/json',
    };
    // Don't do lookup unless postcode is at right length
    if (cleanedPostcode.length < 6 || cleanedPostcode.length > 8) {
      return null;
    }

    const respond = await axios({
      method: 'get',
      url: addUrl,
      headers: headers,
    });
    const parse = await convert.xmlDataToJSON(respond.data);
    if (!parse.Address || parse.Address.ErrorMessage[0] !== '') {
      return null;
    }
    let address = parse.Address;
    let add1 = address.Address1[0];
    let add2 = address.Address2 && address.Address2[0];
    let city = address.Town[0];
    let postcode = address.Postcode[0];

    let completions = address.PremiseData[0].split(';');
    completions = completions.filter(Boolean);
    completions = completions.map(x => {
      let a = x.replace(/^\|+|\|+$/g, ''); // remove one or more '|' characters at the start or end of the string
      let b = a.replace('|', ', '); // replace any remaining '|' characters in the middle of the string with ', '

      return {
        a: [b, add1, add2, city, postcode || cleanedPostcode].filter(Boolean).join(', '),
        pxid: b + '<:>' + postcode,
      };
    });

    return { completions };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getStructuredAddressUk = async function(pxid) {
  let pxidArray = pxid.split('<:>');
  let premise = pxidArray[0];
  let postcode = pxidArray[1];

  const cleanedPostcode = postcode.replace(/\s/g, '');
  const postcodeEn = encodeURIComponent(cleanedPostcode);
  try {
    const addressFinderUrl =
      'https://ws1.postcodesoftware.co.uk/lookup.asmx/getAddress?account=2597&password=gkn67kil&postcode=';
    const addUrl = addressFinderUrl + `${postcodeEn}`;
    const headers = {
      'content-type': 'application/json',
    };
    // Don't do lookup unless postcode is at right length
    if (cleanedPostcode.length < 6) {
      return null;
    }

    const respond = await axios({
      method: 'get',
      url: addUrl,
      headers: headers,
    });
    const parse = await convert.xmlDataToJSON(respond.data);
    if (!parse.Address || parse.Address.ErrorMessage[0] !== '') {
      return null;
    }

    let address = parse.Address;
    let add1 = address.Address1[0];
    let county = address.County && address.County[0];
    let city = address.Town[0];
    let region = address.County[0];
    let postcode = address.Postcode[0];

    return {
      pxid: pxid,
      number: premise,
      street: add1,
      county: county,
      city: city,
      region: region,
      postcode: postcode,
      country: 'gbr',
      x: 0,
      y: 0,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};
