import actions from '../actions';
import { ReducerBuilder } from './reducer-builder';

const defaultState = {
  rehydrated: false,
  justSubmitted: false,
  showPasswordlessLogin: false,
  email: '',
  emailAlreadyExist: false,
  linkSentToEmail: false
};

const reducer = new ReducerBuilder(defaultState)
  .handle(actions.app.temporary.rehydrated, (state, _) => ({
    ...state,
    rehydrated: true
  }))
  .handle(actions.app.temporary.showPasswordlessLogin, (state, action) => ({
    ...state,
    showPasswordlessLogin: action.payload.show
  }))
  .handle(actions.app.temporary.changeEmail, (state, action) => ({
    ...state,
    email: action.payload.email,
    emailAlreadyExist: false
  }))
  .handle(actions.app.temporary.setEmailAlreadyExist, (state, action) => ({
    ...state,
    emailAlreadyExist: true
  }))
  .handle(actions.app.temporary.linkSentToEmail, (state, action) => ({
    ...state,
    linkSentToEmail: action.payload.sent
  }))
  .handle(actions.app.temporary.justSubmitted, (state, _) => ({
    ...state,
    justSubmitted: true
  }))
  .handle(actions.app.auth.logoutSuccess, (state) => ({
    ...state,
    justSubmitted: false,
    showPasswordlessLogin: false,
    linkSentToEmail: false
  }))
  .done();

export default reducer;