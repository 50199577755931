import actions from '../actions';
import { put, takeEvery } from 'redux-saga/effects';
import { createMuiTheme } from '@material-ui/core/styles';

export function* themesFlow() {
  yield takeEvery(actions.app.themes.createTheme, function* takeCreateTheme({
    payload: { colors }
  }) {
    try {
      const { light, main, dark, contrastText } = colors; 
      const newTheme = createMuiTheme({
        palette: {
          primary: {
            light,
            main,
            dark,
            contrastText,
          },
          common: {
            white: '#fff'
          }
        },
        typography: {
          useNextVariants: true,
        },
      });
      yield put(actions.app.themes.createThemeSuccess(newTheme))
    } catch(e) {
      yield put(actions.app.themes.createThemeFailure(e))
    }
  })
}