export default {
  
  sharedOAuthConfig: {
     scope: 'all:access',
  },
  local: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'http://localhost:3000',
      callback: 'http://localhost:3000/callback',
      clientDataKeys: {
        client_id: process.env.REACT_APP_LOCAL_CLIENT_ID
      },
      oAuthConfig: {
        // frontend.myplan.app
        audience: 'https://auth.api.acquire.uk.arken-dev.com',
        domain: 'arken-dev.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_LOCAL_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_LOCAL_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  development: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'https://appacq.devt.arken.legal',
      callback: 'https://appacq.devt.arken.legal/callback',
      clientDataKeys: {
        client_id: process.env.REACT_APP_DEV_CLIENT_ID
      },
      oAuthConfig: {
        // frontend.myplan.app
        audience: 'https://auth.api.acquire.uk.arken-dev.com',
        domain: 'arken-dev.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_DEV_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_DEV_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  testing: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'https://appacq.test.arken.legal',
      callback: 'https://appacq.test.arken.legal/callback',
      // api.myplan.app
      authenticationKeys: {
        client_id: process.env.REACT_APP_TEST_CLIENT_ID
      },
     oAuthConfig: {
        // frontend.myplan.app
        audience: 'https://auth.api.acquire.uk.arken-dev.com',
        domain: 'arken-dev.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_TEST_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_TEST_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  preprod: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'https://appacq.stage.arken.legal',
      callback: 'https://appacq.stage.arken.legal/callback',
      clientDataKeys: {
        client_id: process.env.REACT_APP_PRE_CLIENT_ID
      },
      oAuthConfig: {
        // frontend.myplan.app
         audience: 'https://pre-auth-myplan-uk-eu2.api.arken.legal',
        domain: 'pre-arkenlegal.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_PRE_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_PRE_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  production: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'https://uk.acquire.arken.legal/',
      callback: 'https://uk.acquire.arken.legal/callback',
      clientDataKeys: {
        client_id: process.env.REACT_APP_CLIENT_ID
      },
      oAuthConfig: {
        // frontend.myplan.app
        audience: 'https://auth-myplan-uk-eu2.api.arken.legal',
        domain: 'arkenlegal.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  currencySymbols: {
    aus: '$',
    nzl: '$',
    gbr: '£',
    rsa: 'R',
  },
  location: 'gbr',
  country: 'United Kingdom',
};
