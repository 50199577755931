import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { Typography as Text, LinearProgress } from '@material-ui/core';
import * as cx from 'classnames';

import actions from '../actions';
import * as selectors from '../selectors';
import { QuestionWrap } from '../components';
import { isHidden } from '../utils';

const styles = theme => ({
  root: {
    minHeight: '10vh',
    marginBottom: '2vh',
  },
  flex: { display: 'flex' },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    borderRadius: 40,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  header: {
    alignItems: 'center',
    padding: '1.5vh',
    borderBottom: '3px solid rgb(235,235,235)',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  headerText: {
    marginLeft: '1vw',
    color: theme.palette.secondary.main,
  },
  content: {
    padding: '2vh',
  },
});

function DetailsSection({
  loading,
  classes,
  config,
  details,
  currencySymbol,
  updateDetail,
  updateValidate,
  updateRequiredFields,
  validated,
  updateTempRequiredFields,
}) {
  return (
    <Paper elevation={4} className={classes.root}>
      {loading && <LinearProgress />}
      <div className={cx(classes.flex, classes.header, 'noselect')}>
        {config.icon && (
          <div className={classes.iconWrap}>
            <Icon>{config.icon}</Icon>
          </div>
        )}
        <Text color="inherit" variant="h6" className={classes.headerText}>
          {config.title}
        </Text>
      </div>
      <div className={classes.content}>
        {config.nodes &&
          config.nodes.map((nodeConfig, index) => {
            return (
              !isHidden({
                details,
                hiddenTrigger: nodeConfig.hiddenTrigger,
              }) && (
                <QuestionWrap
                  key={index}
                  {...nodeConfig}
                  updateDetail={updateDetail}
                  updateValidate={updateValidate}
                  updateRequiredFields={updateRequiredFields}
                  details={details}
                  currencySymbol={currencySymbol}
                  updateTempRequiredFields={updateTempRequiredFields}
                  validated={validated}
                />
              )
            );
          })}
      </div>
    </Paper>
  );
}

export default connect(
  (state, _) => ({
    loading: selectors.getAppLoading(state),
    theme: selectors.getActiveTheme(state),
    details: selectors.getDetails(state),
    currencySymbol: selectors.getCurrency(state),
  }),
  (dispatch, _) => ({
    updateDetail(path, value) {
      dispatch(actions.app.data.updateUserData(path, value));
    },
    updateValidate(path, value, details) {
      dispatch(actions.app.appState.updateValidate(path, value, details));
    },
    updateTempRequiredFields(path, value, details) {
      dispatch(actions.app.appState.updateTempRequiredFields(path, value, details));
    },
    updateRequiredFields(path, value, required) {
      dispatch(actions.app.appState.updateRequired(path, value, required));
    },
  }),
)(withStyles(styles)(DetailsSection));
