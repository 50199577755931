import 'core-js/features/array';
import 'core-js/features/promise';
import 'core-js/features/string';
import 'core-js/features/weak-set';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createStore from './store';
import { Provider } from 'react-redux';
import cacheClearer from './cacheClearer';


const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <div version-info = {process.env.REACT_APP_VERSIONINFO}></div>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
cacheClearer();