import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import * as cx from 'classnames';
import { history } from '../store';
import { PageFooter } from '../containers';
import { Register, Details, CallBack, TermsAndConditions } from './index';
import actions from '../actions';
import * as selectors from '../selectors';
import Status from "./Status/Status";

const styles = ({ palette, breakpoints }) => ({
  root: {
    flexGrow: 1,
  },
  companyName: {
    flexGrow: 1,
    color: '#000', // Hardcoding the company name to black as it will be on a white headerbar.
  },
  actionBtn: {
    marginLeft: '10px',
    color: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light,
      color: 'white',
    },
    [breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  currentPath: {
    border: `1px solid ${palette.primary.main}`,
  },
  pageContainer: {
    minHeight: '77vh',
  },
  notification: {
    display: 'flex',
    minHeight: '7vh',
  },
  flexCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blackCircle: {
    width: '5px',
    height: '5px',
    backgroundColor: '#000000',
  },
  logoContainer: {
    flexGrow: 1,
    resizeMode: 'contain',
  },
  headerBar: {
    backgroundColor: '#fff', // Hardcoding the header to white as agreed. Most logos will have to be placed in a white bg anyway.
  },
});

const NavLinks = withStyles(styles)(
  ({ accountDetails, classes, isLoggedIn, logout, pathname, logo }) => (
    <Toolbar color="default">
      {logo ? (
        <div className={classes.logoContainer}>
          <img src={logo}  alt="Logo" />
        </div>
      ) : (
        <Typography variant="h6" className={cx('noselect', classes.companyName)}>
          {(accountDetails &&
            accountDetails.extensionData &&
            accountDetails.extensionData.extensionData &&
            accountDetails.extensionData.extensionData.StaticContent &&
            accountDetails.extensionData.extensionData.StaticContent.companyname) ||
            accountDetails.name}
        </Typography>
      )}
      {isLoggedIn && (
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            variant="text"
            color="primary"
            className={cx(classes.actionBtn, { [classes.currentPath]: pathname === '/' })}
          >
            HOME
          </Button>
        </Link>
      )}
      {isLoggedIn && (
        <Link to="/details" style={{ textDecoration: 'none' }}>
          <Button
            variant="text"
            color="primary"
            className={cx(classes.actionBtn, { [classes.currentPath]: pathname === '/details' })}
          >
            DETAILS
          </Button>
        </Link>
      )}
      {/* {isLoggedIn &&
      <Link to="/themes" style={{textDecoration: 'none'}}>
        <Button variant="text" color="primary" className={cx(
          classes.actionBtn,
          { [classes.currentPath]: pathname === '/themes' }
        )}>
          THEMES
        </Button>
      </Link>
    } */}
      {isLoggedIn && (
        <Button variant="text" color="inherit" className={classes.actionBtn} onClick={logout}>
          LOG OUT
        </Button>
      )}
    </Toolbar>
  ),
);

const Notification = withStyles(styles)(({ classes, closeNotification, details }) => (
  <Paper className={classes.notification}>
    <div style={{ flex: 1 }} className={classes.flexCentered} />
    <div style={{ flex: 8 }} className={classes.flexCentered}>
      <div style={{ marginRight: '5px' }} className={classes.blackCircle} />
      <Typography>
        Hi {details.name.first || ''}, you can start editing your details and hit the 'Submit'
        button to send it to us
      </Typography>
    </div>
    <div style={{ flex: 1 }} className={classes.flexCentered}>
      <IconButton onClick={closeNotification}>
        <Icon style={{ color: 'black' }}>close</Icon>
      </IconButton>
    </div>
  </Paper>
));

const ProtectedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    render={props => (isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)}
    {...rest}
  />
);

function Root({
  details,
  classes,
  accountDetails,
  rehydrated,
  isLoggedIn,
  logout,
  pathname,
  showNotification,
  closeNotification,
  logos,
}) {
  const iframe = pathname === '/iframe';

  return (
    <ConnectedRouter history={history}>
      <div className={classes.root}>
        {!iframe && (
          <AppBar position="static" className={classes.headerBar}>
            <NavLinks
              logo={logos}
              accountDetails={accountDetails}
              isLoggedIn={isLoggedIn}
              logout={logout}
              pathname={pathname}
            />
          </AppBar>
        )}
        {!iframe && pathname === '/details' && showNotification && (
          <Notification closeNotification={closeNotification} details={details} />
        )}
        <div className={classes.pageContainer}>
          {rehydrated && (
            <Switch>
              <ProtectedRoute path="*/details/" isLoggedIn={isLoggedIn} component={Details} />
              <Route path="*/t&c/" component={TermsAndConditions} />
              <Route path="*/callback/" component={CallBack} />
              <Route path="*/iframe/" component={props => <Register iframe {...props} />} />
              <Route path="*/status/" component={Status} /> 
              <Route path="*" component={Register} />
            </Switch>
          )}
        </div>
        {!iframe && accountDetails.name && <PageFooter accountDetails={accountDetails} />}
      </div>
    </ConnectedRouter>
  );
}
export default connect(
  (state, _) => ({
    details: selectors.getDetails(state),
    pathname: state.router.location.pathname,
    isLoggedIn: selectors.getIsLoggedIn(state),
    rehydrated: selectors.getRehydrated(state),
    accountDetails: selectors.getAccountDetails(state) || { address: {} },
    loading: selectors.getAppLoading(state),
    showNotification: selectors.getShowNotification(state),
    validated: selectors.getValidated(state),
    // companyLogo: selectors.getCompanyLogo(state),
  }),
  (dispatch, _) => ({
    logout() {
      dispatch(actions.app.auth.logout());
    },
    closeNotification() {
      dispatch(actions.app.appState.setShowNotification(false));
    },
  }),
)(withStyles(styles)(Root));
