import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography as Text } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import * as cx from 'classnames';
import actions from '../actions';
import * as selectors from '../selectors';
import { validateEmail } from '../helper/Validations';

const styles = theme => ({
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  questionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '1vh',
    paddingLeft: '6px',
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    flex: 1,
    marginLeft: '1%',
    marginRight: '1%',
  },
  ageField: {
    width: '10%',
  },
  genderField: {
    marginLeft: '10px',
    width: '20%',
  },
  questionMain: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  asterisk: {
    color: 'red',
  },
  genderSelect: {
    minWidth: 150,
  },
  emailBox: {
    paddingLeft: '2%',
  },
  actionsWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
  },
  link: {
    cursor: 'pointer',
  },
  termsAndConditionsLink: {
    alignItems: 'start',
  },
  termsAndConditionsLinkCheck: {
    paddingRight: '5px',
    paddingTop: '1px',
  },
});

function RegisterForm({
  liveProfile,
  details,
  classes,
  updateDetail,
  registerClient,
  showPasswordless,
  termsAndConditionsTicked,
  handleTermsAndConditionsTicked,
  accountDetails,
  extensionData,
  registration,
}) {

  const companyTAndC =
    accountDetails &&
    accountDetails.extensionData &&
    accountDetails.extensionData.extensionData &&
    accountDetails.extensionData.extensionData.DnsInfo &&
    accountDetails.extensionData.extensionData.DnsInfo.companyTermsConditions;

  const { termsAndConditionsText, termsAndConditionsLink } = registration;
  const { name: companyName } = accountDetails;
  const makeTCsLink = () =>
    parse(termsAndConditionsText, {
      replace() {
        const termsAndConditions = termsAndConditionsText.replace(
          'company_name',
          (accountDetails &&
            accountDetails.extensionData &&
            accountDetails.extensionData.extensionData &&
            accountDetails.extensionData.extensionData.StaticContent &&
            accountDetails.extensionData.extensionData.StaticContent.companyname) ||
          companyName,
        );
        const preTerms = termsAndConditions.split(termsAndConditionsLink)[0];
        const postTerms = termsAndConditions.split(termsAndConditionsLink)[1];

        return (
          (
            <>
              {preTerms}

              <a href={companyTAndC} target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>

              {postTerms}
            </>
          ) || (
            <>
              {preTerms}
              <Link to="/t&c" style={{ whiteSpace: 'nowrap' }}>
                {termsAndConditionsLink}
              </Link>
              {postTerms}
            </>
          )
        );
      },
    });

  return (
    <div>
      <div className={cx(classes.questionWrap, classes.noPaddingTop)}>
        <div className={classes.questionHeader}>
          <Text variant="h6">My name is</Text>
          <span className={classes.asterisk}>*</span>
        </div>
        <div className={cx(classes.flexWrap, classes.questionMain)}>
          <TextField
            autoFocus
            label="First Name"
            id="firstname"
            className={classes.textField}
            value={details.name.first || ''}
            onChange={e => updateDetail('name.first', e.target.value)}
            margin="none"
            required
          />
          <TextField
            label="Middle Names"
            id="middlenames"
            className={classes.textField}
            value={details.name.middle || ''}
            onChange={e => updateDetail('name.middle', e.target.value)}
            margin="none"
          />
          <TextField
            label="Surname"
            id="surname"
            className={classes.textField}
            value={details.name.surname || ''}
            onChange={e => updateDetail('name.surname', e.target.value)}
            margin="none"
            required
          />
        </div>
      </div>

      <div className={classes.questionWrap}>
        <div className={classes.questionHeader}>
          <Text variant="h6">I am a </Text>
          <span className={classes.asterisk}>*</span>
          <TextField
            id='age'
            style={{ paddingLeft: '10px', paddingRight: '10px' }}
            className={classes.ageField}
            value={details.age || ''}
            error={!!details.age && Number(details.age) < 16}
            onChange={e => {
              if (Number(e.target.value) >= 0) {
                updateDetail('age', e.target.value);
              }
            }}
            margin="none"
            type="number"
            required
          />
          <Text variant="h6">year old </Text>
          <Select
            id='gender'
            value={details.gender || ''}
            onChange={e => updateDetail('gender', e.target.value)}
            className={classes.genderField}
            displayEmpty
            name="gender"
            placeholder="Gender"
            inputProps={{
              id: 'gender-required',
            }}
          >
            <MenuItem value="Unspecified" />
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </div>
        <div>
          <FormHelperText>
            Our service is only available to those 18 years of age and older
          </FormHelperText>
        </div>
      </div>

      <div className={classes.questionWrap}>
        <div className={classes.questionHeader}>
          <Text variant="h6">and I am </Text>
          <FormControl className={cx(classes.textField, classes.emailBox)}>
            <Select
              id='maritalstatus'
              value={details.maritalStatus || ''}
              onChange={e => updateDetail('maritalStatus', e.target.value)}
              displayEmpty
              name="relationship"
              placeholder="Relationship"
              inputProps={{
                id: 'relationship-required',
              }}
            >
              <MenuItem value="" disabled>
                Relationship
              </MenuItem>
              <MenuItem value="unspecified">Unspecified</MenuItem>
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
              <MenuItem value="widow">Widowed</MenuItem>
              <MenuItem value="civilpartnership">Civil Partnership</MenuItem>
              <MenuItem value="inpartnership">Living as Partners</MenuItem>
              <MenuItem value="separated">Separated</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={classes.questionWrap}>
        <div className={classes.questionHeader}>
          <Text variant="h6">and my email is</Text>
          <span className={classes.asterisk}>*</span>
          <TextField
            id="email"
            className={cx(classes.textField, classes.emailBox)}
            placeholder="myemail@provider.com"
            onChange={e => updateDetail('email', e.target.value)}
            value={details.email || ''}
            error={!!details.email && !validateEmail(details.email)}
            margin="none"
            required
          />
        </div>
        {details.email && !validateEmail(details.email) && (
          <div>
            <FormHelperText color="red">Please enter a valid email to continue</FormHelperText>
          </div>
        )}
      </div>

      <div>
        <FormHelperText>
          <span className={classes.asterisk}>*</span>
          Required fields
        </FormHelperText>
      </div>

      <div className={classes.questionWrap}>
        <FormControlLabel
          control={
            <Checkbox
              id="termsAndConditions"
              checked={termsAndConditionsTicked}
              onChange={handleTermsAndConditionsTicked}
              value="termsAndConditionsTicked"
              color="primary"
              className={classes.termsAndConditionsLinkCheck}
            />
          }
          label={makeTCsLink()}
          className={classes.termsAndConditionsLink}
        />
      </div>
      <div className={classes.actionsWrap}>
        <div className={classes.flexColumn}>
          <Button
            id="registerClient"
            variant="contained"
            color="primary"
            onClick={registerClient}
            disabled={
              !liveProfile ||
              !termsAndConditionsTicked ||
              !details.name.first ||
              !details.name.surname ||
              Number(details.age || 0) < 16 ||
              !validateEmail(details.email)
            }
            style={{ marginBottom: '8px' }}
          >
            GET STARTED
          </Button>
          <Button color="primary" className={classes.link} onClick={showPasswordless}>
            <Text color="inherit" variant="caption">
              or Log in to continue
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, _) => ({
    details: selectors.getDetails(state),
    termsAndConditionsTicked: selectors.getTermsAndConditionsTicked(state),
  }),
  (dispatch, ownProps) => ({
    updateDetail(path, value) {
      dispatch(actions.app.data.updateUserData(path, value));
    },
    registerClient() {
      if (ownProps.iframe) {
        dispatch(actions.app.temporary.openInNewWindow());
      } else {
        dispatch(actions.app.data.registerClient(ownProps.iframe));
      }
    },
    handleTermsAndConditionsTicked(event) {
      dispatch(actions.app.appState.setTermsAndConditionsTicked(event.target.checked));
    },
  }),
)(withStyles(styles)(RegisterForm));
