import moment from 'moment';

const isEmpty = (str) => str !== 0 && !str;

const minAge = 16;
const maxAge = 110;

const validateAge = (timeStamp) => {
  const age = moment().diff(moment(timeStamp), 'years');
  return age >= minAge && age <= maxAge;
};

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && re.test(String(email).toLowerCase());
};

const validateNumber = (str) => isEmpty(str) || !Number.isNaN(Number(str));

const validateYear = (str) => isEmpty(str) || (str.length === 4 && validateNumber(str));

const validateBirthYear = (str) =>
  isEmpty(str) || (str.length === 4 && validateNumber(str) && moment().year() >= Number(str));

const validateDateOfBirth = (date) => {
  if (date === '') {  return false;}
  return dateOlderThan16(date) && dateLessThan110(date);
};

const dateOlderThan16 = (date) => moment().subtract(minAge, 'year') > moment(new Date(date));

const dateLessThan110 = (date) => moment().subtract(maxAge, 'year') <= moment(new Date(date));

const validateValue = value => isEmpty(value);

const getInvalidDateReasonText  = (date) => 
  {
    //try to get  a moment date. 
    if(typeof(date) !== moment) { date = moment(new Date(date));}

    if(!date.isValid()){return "A date of birth is required "; }
    
    if (!dateOlderThan16(date)){return `Age can't be less than ${minAge}`; }
    
    if(!dateLessThan110(date)){return `Age can't be more than ${maxAge}`;}
        
    return '';
  }

export {
  validateValue,
  validateAge,
  validateEmail,
  validateNumber,
  validateYear,
  validateBirthYear,
  validateDateOfBirth,
  getInvalidDateReasonText,
  dateOlderThan16,
  dateLessThan110 
};