import packageJson from '../package.json';

let clearBroserCache = () => {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      });
    });
  }
};

const version = localStorage.getItem('version');

const renew = version!==packageJson.version;

export default function cacheClearer() {

      if(renew)
      {
        clearBroserCache();
         // Makes sure the page reloads. Changes are only visible after you refresh.
         window.location.reload(true);
         localStorage.clear();
         localStorage.setItem('version',packageJson.version);
      }



}