import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '8vh',
    marginTop: '1vh',
    marginBottom: '5vh',
  },
  companyLogoContiner: {
    flexGrow: 1,
    marginTop: '10px',
  },
  companyLogo: {
    height: '25px',
  },
  detailsBox: {
    display: 'flex',
    width: '80vw',
    borderTop: '0.5px solid rgb(230,230,230)',
    padding: '1vh',
  },
  infoWrap: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  infoWrapRight: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    textAlign: 'right',
  },
  infoItemsAlignBottom: {
    alignSelf: 'flex-end',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '75%',
  },
  contactText: {
    paddingLeft: '5px',
  },
});

function PageFooter({ classes, accountDetails }) {
  const acquireAddress1 =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.StaticContent &&
      `${accountDetails.extensionData.extensionData.StaticContent.address1}, `) ||
    '';

  const acquireAddress2 =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.StaticContent &&
      `${accountDetails.extensionData.extensionData.StaticContent.address2}, `) ||
    '';

  const acquireAddress3 =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.StaticContent &&
      `${accountDetails.extensionData.extensionData.StaticContent.address3}, `) ||
    '';

  const acquirePostcode =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.StaticContent &&
      accountDetails.extensionData.extensionData.StaticContent.postcode) ||
    '';

  const acquireAddress = [acquireAddress1 + acquireAddress2 + acquireAddress3 + acquirePostcode];

  const companyAddress = [
    (`${accountDetails.address.street}, ` || '') +
      (`${accountDetails.address.suburb}, ` || '') +
      (`${accountDetails.address.city}, ` || '') +
      (`${accountDetails.address.postcode}` || ''),
  ];

  return (
    <div className={classes.footerContainer}>
      <div className={classes.detailsBox}>
        <div className={classes.infoWrap}>
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              Get in touch
            </Typography>
            <div>
              {(accountDetails.telephone && (
                <div>
                  <span className={classes.info}>
                    <Icon fontSize="small">phone</Icon>
                    <Typography variant="caption" className={classes.contactText}>
                      {(accountDetails &&
                        accountDetails.extensionData &&
                        accountDetails.extensionData.extensionData &&
                        accountDetails.extensionData.extensionData.StaticContent &&
                        accountDetails.extensionData.extensionData.StaticContent.telephone) ||
                        accountDetails.telephone}
                    </Typography>
                  </span>
                </div>
              )) ||
                ''}
              {(accountDetails.email && (
                <div>
                  <span className={classes.info}>
                    <Icon fontSize="small">mail</Icon>
                    <Typography variant="caption" className={classes.contactText}>
                      {(accountDetails &&
                        accountDetails.extensionData &&
                        accountDetails.extensionData.extensionData &&
                        accountDetails.extensionData.extensionData.StaticContent &&
                        accountDetails.extensionData.extensionData.StaticContent.email) ||
                        accountDetails.email}
                    </Typography>
                  </span>
                </div>
              )) ||
                ''}
              <div>
                <span className={classes.info}>
                  <Icon fontSize="small">location_on</Icon>
                  <Typography variant="caption" className={classes.contactText}>
                    {acquireAddress || companyAddress}
                  </Typography>
                </span>
              </div>
            </div>
          </div>
          <div className={classes.infoWrapRight} style={{ alignItems: 'right' }}>
            <div className={classes.infoItemsAlignBottom}>
              <Typography variant="caption">
                {(accountDetails &&
                  accountDetails.extensionData &&
                  accountDetails.extensionData.extensionData &&
                  accountDetails.extensionData.extensionData.StaticContent &&
                  accountDetails.extensionData.extensionData.StaticContent.companyname) ||
                  accountDetails.name}
              </Typography>
              <Typography variant="caption">
                Powered by{' '}
                <a href="https://arken.legal/" target="_blank" rel="noopener noreferrer">
                  Arken.legal
                </a><br />Acquire v{process.env.REACT_APP_VERSION}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(PageFooter);
